/* Mobile 320px - 767px -- 812px */

.not-found-continar {
    position: relative;
    margin: 3em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.not-found-circle {
    background-color: var(--not-found-color);
    width: 235px;
    height: 235px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.not-found-search-icon {
    position: relative;
    left: 10px;
    font-size: 120px;
    color: var(--background-color);
}

.not-found-x-icon {
    position: absolute;
    top: 48px;
    left: 77px;
    font-size: 80px;
    color: var(--background-color);
}

.not-found-text {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    color: var(--not-found-color);
}

/* Tablet 768px - 1279px -- 800px*/
@media screen and (min-width: 768px) {

    .not-found-circle {
        width: 335px;
        height: 335px;
    }

    .not-found-search-icon {
        font-size: 170px;
    }

    .not-found-x-icon {
        top: 70px;
        left: 107px;
        font-size: 110px;
    }
}