/* Mobile 320px - 767px -- 812px */
td.leaderboard-table-td {
	padding: 10px 12px;
	font-size: 12px;
	text-align: start;
	min-width: 130px;
}

tr:nth-child(even) td.leaderboard-table-td {
	background: var(--table-row2-color);
}

tr:nth-child(odd) td.leaderboard-table-td {
	background: var(--table-row1-color);
}

/* Tablet 768px - 1279px -- 800px*/
@media screen and (min-width: 768px) {
	td.leaderboard-table-td {
		padding: 12px 20px;
		font-size: 16px;
	}
}