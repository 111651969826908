/* Mobile 320px - 767px -- 812px */
.validator-table-container{
    min-width: auto;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    overflow-x: scroll;
    border-radius: 10px;
}

tr:nth-child(even) td.validator-table-td {
	background: var(--table-row2-color);
}
tr:nth-child(odd) td.validator-table-td {
	background: var(--table-row1-color);
}

td.validator-table-td{
    padding: 7px 20px;
}

.validator-table-td.validator-table-td-center{
    text-align: center;
}


/* Laptop HD 1280px - 1439px -- 720px*/
@media screen and (min-width: 1280px) {
    .validator-table-container {
        width: 100%;
        max-width: 75vw;
        overflow-x:unset;
        border-radius: 0;
    }
}