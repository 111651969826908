/* Mobile 320px - 767px -- 812px */
.table-thead-thead {
    color: var(--text-color) !important;
}

.table-thead-th.table-thead-th-center {
    text-align: start;
}

.table-thead-th {
    padding: 10px 12px;
    font-size: 12px;
    text-align: start;
    min-width: 140px;
}

/* Tablet 768px - 1279px -- 800px*/
@media screen and (min-width: 768px) {
    .table-thead-th {
        padding: 15px 20px;
        font-size: 16px;
        min-width: auto;
    }

    .table-thead-th.table-thead-th-center {
        text-align: center;
    }
}