:root {
    --background-color: #2D354D;
    --text-color: #222222;
    --icon-color: #FFFFFF;

    --title-color: #FFFFFF;
    --title-color-hover: #FFFFFF90;
    --title-color-active: #FFFFFF80;

    --link-color: #305BF3;
    --link-color-hover: #5678F1;
    --link-color-active: #6886F1;

    --fotter-text-color: #FFFFFF50;
    --fotter-link-color: #FFFFFF50;
    --fotter-link-color-hover: #FFFFFF60;
    --fotter-link-color-active: #FFFFFF80;

    --table-color: #FFFFFF;
    --table-row1-color: #FFFFFF;
    --table-row2-color: #eef1f4;
    --inner-table-header-color: #D9D9D950;
    --table-header-color: #D9D9D9;

    --inspect-color: #4A5780;
    --inspect-color-hover: #5a668c;
    --inspect-color-active: #727ea5;

    --search-color: #EBEBEB;
    --search-btn-color: #D9D9D9;
    --search-btn-color-hover: #E3E3E3;
    --search-btn-color-active: #E8E8E8;

    --not-found-color: #282E43;

    --pagination-select-color: #478BC3;

	--link-color-primary: #ffffff;
	--link-color-primary-hover: #ffffff95;
	--link-color-primary-active: #ffffff75;
}

html,
body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
    background-color: var(--background-color);
}

body {
	font-family: 'Aboreto', cursive;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-size: 16px;
	font-weight: 400;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

body.fontLoaded {
	font-family: 'Aboreto', cursive;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-size: 16px !important;
	font-weight: 400;
}

#app {
	min-height: 100%;
	min-width: 100%;
	padding: 0;
	margin: 0;
}

.monospace-number {
    font-variant-numeric: tabular-nums;
    text-align: end;
}