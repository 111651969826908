.navbar-link {
    background-color: aqua;
}

.navbar-link-a {
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--link-color-primary);
}

.navbar-link-a:hover {
    cursor: pointer;
    color: var(--link-color-primary-hover);
}

.navbar-link-a:active,
.navbar-link-a:focus {
    cursor: pointer;
    color: var(--link-color-primary-active);
}

.actives{
    pointer-events: none;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--link-color-primary-active);
}