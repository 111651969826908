/* Mobile 320px - 767px -- 812px */
.template-container{
    position: relative;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
    align-items: center;
	margin: 0;
	padding: 0;
	min-height: 100vh;
	height: 100%;
	min-width: 100%;
	width: 100%;
	padding-bottom: 70px;
    background-color: var(--background-color);
}

.template-children-container{
    width: 100%;
    max-width: 90vw;
    height: 100%;
    margin: 2em 0;
}

.template-footer{
    margin: 2em 0;
    font-size: 0.7rem;
    color: var(--fotter-text-color);
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.template-footer-a{
    color: var(--fotter-link-color);
}

.template-footer-a:hover{
    cursor: pointer;
    color: var(--fotter-link-color-hover);
}

.template-footer-active{
    cursor: pointer;
    color: var(--fotter-link-color-active);
}

/* Tablet 768px - 1279px -- 800px*/
@media screen and (min-width: 768px) {
    .template-footer{
        font-size: 1rem;
    }
}