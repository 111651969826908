/* Mobile 320px - 767px -- 812px */
tr.inspect-inner-table-tbody-tr:nth-child(even) td.inspect-inner-table-td {
    background: var(--table-row2-color);
}

tr.inspect-inner-table-tbody-tr:nth-child(odd) td.inspect-inner-table-td {
    background: var(--table-row1-color);
}

td.inspect-inner-table-td {
    padding: 10px 12px;
    font-size: 12px;
    min-width: 130px;
    text-align: start;
} 

.inspect-inner-table-td-link{
    color: var(--text-color);
}
.inspect-inner-table-td-link:hover{
    cursor: pointer;
    border-bottom: 1px solid var(--text-color);

}

/* Tablet 768px - 1279px -- 800px*/
@media screen and (min-width: 768px) {
    td.inspect-inner-table-td{
        font-size: 16px;
    }
}