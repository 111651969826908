/* Mobile 320px - 767px -- 812px */
.pagination {
  border-radius: 0 0 8px 8px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  font-size: 16px;
  margin-left: 15px;
}

.pagination button {
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
}
.pagination button:hover{
  cursor: pointer;
}
.pagination button:disabled{
  cursor: default;
}

.previous,
.next {
	color: var(--text-color) !important;
}
.number {
	margin: 0 10px;
	color: var(--text-color) !important;
}
.active {
	color: var(--pagination-select-color) !important;
	font-weight: 600;
  font-size: 20px;
}

.next-end,
.next-end:hover,
.previous-start,
.previous-start:hover{
  color: var(--text-color) !important;
  cursor: default;
}

/* Laptop HD 1280px - 1439px -- 720px*/
@media screen and (min-width: 1280px) {
  .pagination {
    padding: 10px 0;
    margin-left: 0;
    justify-content: center;
  }
}