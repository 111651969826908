/* Mobile 320px - 767px -- 812px */
.search-container {
    min-width: 100px;
    width: 100%;
    max-width: 548px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.search-input {
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    border: 1px solid rgba(0, 0, 0, 0.30);
    background-color: var(--search-color);
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.25);
    padding: 0 5px;
}

.search-btn {
    width: 54px;
    height: 100%;
    border-radius: 0 10px 10px 0;
    border: none;
    background-color: var(--search-btn-color);
    color: var(--text-color);
}

.search-btn:hover {
    cursor: pointer;
    background-color: var(--search-btn-color-hover);
}

.search-btn:active {
    cursor: pointer;
    background-color: var(--search-btn-color-active);
}

/* Tablet 768px - 1279px -- 800px*/
@media screen and (min-width: 768px) {
    .search-container {
        font-size: 16px;
    }

    .search-input {
        padding: 0 20px;
    }

    .search-btn {
        width: 64px;
    }
}