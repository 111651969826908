/* Mobile 320px - 767px -- 812px */
.leaderboard-table-container {
    min-width: auto;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    overflow-x: scroll;
    border-radius: 10px;
}


/* Laptop HD 1280px - 1439px -- 720px*/
@media screen and (min-width: 1280px) {
    .leaderboard-table-container {
        width: 90%;
        max-width: 75vw;
        overflow-x:unset;
        border-radius: 0;
    }
}