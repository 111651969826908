/* Mobile 320px - 767px -- 812px */
.my-modal{
    width: auto;
    max-width: auto;
}

.inspect-container {
    margin: 2em 0.5em;
    min-width: auto;
    width: 90%;
    max-width: 90vw;
    height: 100%;
    overflow-x: scroll;
    border-radius: 10px;
}

.inspect-loading-continar {
    width: 100%;
    height: 100%;
    position: relative;
    left: 50%;
    margin: 2em 0;
}


.inspect-table-tr td.inspect-table-td {
    width: 100%;
    padding: 15px;
    border: 2px solid var(--inner-table-header-color);
}

/* Tablet 768px - 1279px -- 800px*/
@media screen and (min-width: 768px) {
    .my-modal{
        width: 95vw !important;
        max-width: 95vw !important;
    }

    .inspect-container {
        margin: 2em 0.5em;
        width: auto;
        max-width: 100%;
    }
}


/* Laptop HD 1280px - 1439px -- 720px*/
@media screen and (min-width: 1280px) {
    .my-modal{
        width: 70vw !important;
        max-width: 70vw !important;
    }

    .inspect-container {
        margin: 2em 1em;
        overflow-x:unset;
        border-radius: 0;
        width: auto;
        max-width: 100%;
    }
}