/* Mobile 320px - 767px -- 812px */
.header-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1.5px solid #FFFFFF10;
}

.header-logo {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--title-color);
    text-decoration: none;
    margin-left: 1em;
}

.header-logo-image {
    width: 60px;
}

.header-logo-text{
    display: none;
}

.header-testnet{
    font-size: 14px;
    position: absolute;
    top: 1em;
    left: 50%;
    transform: translate(-50%);
}

.navbar-toggler {
	position: relative;
    right: 1em;
}

.navbar-collapse {
    margin-top: 5px;
	position: relative;
	width: 100%;
}

.navbar-nav {
    padding-right: 1em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5em;
}


.header-reload {
    color: var(--title-color);
    font-size: 34px;
}

.header-reload:hover {
    cursor: pointer;
    color: var(--title-color-hover);
}

.header-reload:active {
    cursor: pointer;
    color: var(--title-color-active);
}

.navbar-tabs {
    align-items: center;
    display: flex;
    gap: 25px;
}

.navbar-divider {
    cursor: default;
    padding-bottom: 5px;
	text-decoration: none;
	font-weight: 300;
	font-size: 24px;
	line-height: 24px;
	color: var(--link-color-primary);
    display: none;
}

/* Laptop HD 1280px - 1439px -- 720px*/
@media screen and (min-width: 1280px) {
    .header-logo-image {
        width: 70px;
    }
    
    .header-logo-text{
        display: inline-block;
    }
    
    .navbar-divider {
        display: inline-block;
    }
}