/* Mobile 320px - 767px -- 812px */
.validator-table-container {
    width: auto;
    max-width: 90%;
    height: 100%;
}

table.validator-table-table {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

thead.validator-table-thead {
    background-color: var(--table-header-color) !important;
    color: var(--text-color) !important;
}

tr:nth-child(even) td.validator-table-td {
    background: var(--table-row2-color);
}

tr:nth-child(odd) td.validator-table-td {
    background: var(--table-row1-color);
}

td.validator-table-td {
    padding: 10px 12px;
    font-size: 12px;
    text-align: start;
}

.validator-table-td.validator-table-td-center {
    text-align: center;
}

/* Tablet 768px - 1279px -- 800px*/
@media screen and (min-width: 768px) {
    td.validator-table-td {
        padding: 12px 20px;
        font-size: 16px;
    }
}