/* Mobile 320px - 767px -- 812px */
td.bundles-table-td{
    padding: 5px 12px;
    font-size: 12px;
    min-width: 100px;
    text-align: start;
}

.bundles-table-td-link{
    color: var(--text-color);
}

.bundles-table-td-link:hover{
    cursor: pointer;
    border-bottom: 1px solid var(--text-color);
}

tr:nth-child(even) td.bundles-table-td {
    background: var(--table-row2-color);
}

tr:nth-child(odd) td.bundles-table-td {
    background: var(--table-row1-color);
}


/* Tablet 768px - 1279px -- 800px*/
@media screen and (min-width: 768px) {
    td.bundles-table-td{
        padding: 12px 20px;
        font-size: 16px;
        min-width: auto;
    }
}